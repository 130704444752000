import React from 'react';

import i18n from '../i18n';
import { withNamespaces } from 'react-i18next';
const changeLanguage = (event,  lng) => {
    event.preventDefault();
    i18n.changeLanguage(lng);
  }
export default class About extends React.Component {
    render(){
        return(
            <section className="page-section" id="about">
              <div className="container">
                <div className="text-center">
                  <h2 className="section-heading text-uppercase">{i18n.t('About')}</h2>
                  <h5 className="section-subheading text-muted">{i18n.t('AboutSubHead')}</h5> <br />
                </div>
                <ul className="timeline">
                  <li>
                    <div className="timeline-image"><i className="fas fa-history fa-stack-2x fa-inverse" /></div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="subheading">{i18n.t('History')}</h4>
                      </div>
                      <div className="timeline-body"><p className="text-muted">{i18n.t('HistoryText')}</p></div>
                    </div>
                  </li>
                  <li className="timeline-inverted">
                    <div className="timeline-image"><i className="fas fa-question fa-stack-2x fa-inverse" /></div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="subheading">{i18n.t('Why')}</h4>
                      </div>
                      <div className="timeline-body"><p className="text-muted">{i18n.t('WhyText')}</p></div>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-image"><i className="fas fa-comment-check fa-stack-2x fa-inverse" /></div>
                    {/*                         <div class="timeline-image"><img class="rounded-circle img-fluid" src="assets/img/about/placeholderabout.jpeg" alt="links" /></div>
           */}                        <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="subheading">{i18n.t('Nature')}</h4>
                      </div>
                      <div className="timeline-body"><p className="text-muted">{i18n.t('NatureText')}</p></div>
                    </div>
                  </li>
                  <li className="timeline-inverted">
                    <div className="timeline-image"><i className="fas fa-trophy fa-stack-2x fa-inverse" /></div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="subheading">{i18n.t('Achievments')}</h4>
                      </div>
                      <div className="timeline-body"><p className="text-muted">{i18n.t('AchievmentsText')}</p></div>
                    </div>
                  </li>
                  <li className="timeline-inverted">
                    <div className="timeline-image">
                      <h4>{i18n.t('Become1')}</h4>
                    </div>
                  </li>
                </ul>
              </div>
            </section>
        )
    }
}

import React from 'react';
import '../../App.css';
import i18n from '../../i18n';

import { Link } from 'react-router-dom'
import Team from '../../components/team.js';
import Nav from '../../components/nav.js';

import Footer from '../../components/footer.js'
import CookieConsent from "react-cookie-consent";
import { withNamespaces } from 'react-i18next';
const posts = [
  {
    title: `Projekta "Platforma Covid-19 drošai darba videi" (identifikācijas nr 1.1.1.1/21/A/011) otrā ceturkšņa pārksats.`,
    content: 'Pēc pirmajā cetruksnī veiktajiem pētījumu rezultātiem, ir uzsākta platformas arhitektūras modelēšana. Tiek meklēti labākie risinājumi datu savākšanai, apmaiņai un attēlošanai. Datu ievākšanas arhitektūra no sensorikas ir tapusi uz papīra un tiek virzīta uz pirmās izstrades stadiju. Paralēli tiek veikta iekārtu iepirkumu tirgus izpēte, kuras rezultātā tiks noskaidrots ar kādu tehnoloģiju efektīvāk spēsim ievākt datus par darba vides apstākļiem kā arī jau tiek veikta dažādu iekārtu fiziska testēšana uzņēmuma telpās.',
    date: '01.07.2022',
    // label: 'WH Time & Attendance',
    url: '/blog/eraf/secondQuarter',
    img: 'assets/blog/blog-2.png',
  },

]
class SinglePost extends React.Component {
  constructor(props) {
      super(props);

      this.state = {

      };
  }

  render(){
      const {  dati, loading, items } = this.state;
        return (
          <>
            <div className="blog container-fluid col-md-12" id="blog" style={{ height: '100%'}}>
            <Nav />
                <div style={{overflow: 'hidden', justifyContent: 'center'}} className="row col-md-12">
                <div to="/blog/dlus-cena" className="blog-single col-md-12" style={{ borderRadius: 15, backgroundColor: 'rgb(36, 40, 48) !important'}}>

                <div className="img col-md-12" style={{ width: '35%' }}>
                   <img src={posts[0].img} alt="Portfolio Item" />
                </div>
                <div className="col-md-12 mt-3">
                  <h3> {posts[0].title} </h3>
                </div>
                <div className="col-md-8 mt-2">

                <p> {posts[0].content} </p>
                </div>
                  </div>
                  </div>
                  <Team />
            </div>
            <Footer color="white" bgColor="#242830" />
            </>
        );
      }
  }



export default withNamespaces()(SinglePost);

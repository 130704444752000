import React from 'react';
import '../../App.css';
import i18n from '../../i18n';

import { Link } from 'react-router-dom'
import Nav from '../../components/nav.js';
import Footer from '../../components/footer.js'
import CookieConsent from "react-cookie-consent";
import { withNamespaces } from 'react-i18next';
const posts = [
  // {
  //   title: 'Cik maksā darba laika uzskaites sistēma?',
  //   content: 'Teksts',
  //   date: '07.09.2021',
  //   // label: 'WH Time & Attendance',
  //   img: 'assets/blog/blog1.png',
  //   url: '/blog/dlus-cena',
  // },
  {
    title: `DTG sadarbībā ar RTU uzsāk ERAF projektu`,
    content: 'Teksts',
    date: '01.01.2022',
    // label: 'WH Time & Attendance',
    url: '/blog/eraf',
    img: 'assets/blog/blog-2.png',
  },
  {
    title: `Projekta "Platforma Covid-19 drošai darba videi" (identifikācijas nr 1.1.1.1/21/A/011) pirmā ceturkšņa pārksats.`,
    content: 'Teksts',
    date: '01.04.2022',
    // label: 'WH Time & Attendance',
    url: '/blog/eraf/firstQuarter',
    img: 'assets/blog/blog-2.png',
  },
  {
    title: `Projekta "Platforma Covid-19 drošai darba videi" (identifikācijas nr 1.1.1.1/21/A/011) otrā ceturkšņa pārksats.`,
    content: 'Pēc pirmajā cetruksnī veiktajiem pētījumu rezultātiem, ir uzsākta platformas arhitektūras modelēšana. Tiek meklēti labākie risinājumi datu savākšanai, apmaiņai un attēlošanai. Datu ievākšanas arhitektūra no sensorikas ir tapusi uz papīra un tiek virzīta uz pirmās izstrades stadiju. Paralēli tiek veikta iekārtu iepirkumu tirgus izpēte, kuras rezultātā tiks noskaidrots ar kādu tehnoloģiju efektīvāk spēsim ievākt datus par darba vides apstākļiem kā arī jau tiek veikta dažādu iekārtu fiziska testēšana uzņēmuma telpās.',
    date: '01.07.2022',
    // label: 'WH Time & Attendance',
    url: '/blog/eraf/secondQuarter',
    img: 'assets/blog/blog-2.png',
  },
  {
    title: `Projekta "Platforma Covid-19 drošai darba videi" (identifikācijas nr 1.1.1.1/21/A/011) trešā ceturkšņa pārksats.`,
    content: 'Projekta ietvaros, trešā ceturkšņa izskaņā apkopoto darbu sarakstā jau ir manāma prototipa izstrāde. Tika finalizēta programmatūras struktūra un projektēšana, kā arī nonākts galā ar pētījumu par nepieciešamo iekārtu iegādi datu ievākšanai. Pirmās izstrādes fāzes ietvaros tika izveidota struktūra jau padziļinātā programmatūras izveides līmenī, kas sevī ietver datu relāciju definēšanu, datu apstrādes algoritmu izveidi un kā arī kopējās lietotāja vietnes izskata dizainu.',
    date: '01.10.2022',
    // label: 'WH Time & Attendance',
    url: '/blog/eraf/thirdQuarter',
    img: 'assets/blog/blog-2.png',
  },
  {
    title: `Projekta "Platforma Covid-19 drošai darba videi" (identifikācijas nr 1.1.1.1/21/A/011) ceturtā ceturkšņa pārksats.`,
    content: 'Projekta ietvaros, ceturtā ceturkšņa izskaņā apkopoto darbu sarakstā jau ir uzsākta noteiktu prototipa elementu testēšana. Ir izstrādāts un ieviests sensoru tīkls uzņēmuma birojā un tiek veikta datu ievākšana un apstrāde. Tiek uzsākta vizuālā noformējuma un lietotāja platformas izstrāde un pilnveidošana',
    date: '01.01.2023',
    // label: 'WH Time & Attendance',
    url: '/blog/eraf/fourthQuarter',
    img: 'assets/blog/blog-2.png',
  }
]
class Blog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    render(){
        const {  dati, loading, items } = this.state;
          return (
            <>
              <div className="blog container-fluid col-md-12" id="blog" style={{ height: '100vh'}}>
              <Nav />
                  <div style={{overflow: 'hidden', justifyContent: 'center'}} className="row col-md-12">
                  {posts.map((each) => {
                    return (
                      <Link to={each.url} className="blog-item col-xs-12 col-sm-12 col-md-12 col-lg-3" style={{ height: 'auto', borderRadius: '10px'}}>
                          <div class="img" style={{ height: '250px', width: 'auto' }}>
                            <img src={each.img} alt="Portfolio Item" style={{ filter: 'brightness(.9)',  borderRadius: '10px 10px 0px 0px', maxWidth: '100%', maxHeight: '100%', height: '100%', margin: 'auto', display: 'block'}}/>
                          </div>
                          <div className="p-2 pb-0 mb-0 pt-2 col-md-12 d-flex" style={{ backgroundColor: 'rgb(36, 40, 48)', height: '100%'}}>
                            <div className="blog-caption pt-4 col-md-12  " style={{ backgroundColor: 'rgb(36, 40, 48)' }}>
                              <div className="blog-caption-heading text-center">
                                {each.title}
                              </div>
                              <div className="text-right pt-0 px-0 mb-0" style={{ backgroundColor: 'rgb(36, 40, 48)' }}>
                                {each.date}
                              </div>
                            </div>
                          </div>
                      </Link>
                    )
                  })}
                    </div>
              </div>
              </>
          );
        }
    }



export default withNamespaces()(Blog);

import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import i18n from '../i18n';
export default class Experience extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: 3,
            slideBy: 3,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });

      if (window.innerWidth < 1198) {
          this.setState({
              items: 2,
              slideBy: 2,
          })
      } else {
            this.setState({
                items:3 ,
                slideBy: 3,
            })}
    }
    componentDidMount(){
         window.addEventListener('resize', this.updateWindowDimensions);
         this.updateWindowDimensions();
    }
    render(){
        const { items, slideBy } = this.state;
        const options = {
            items:items,
            slideBy: slideBy,
            autoplay: true,
            autoplayTimeout:4000,
            loop: true,
            className:'owl-carousel',
            autoplaySpeed:700,
            navSpeed:700,
            dotsSpeed:700,
            lazyLoad: true,
            lazyContent: true,
};

        return(
            <section className="page-section bg-light" id="portfolio">
              <div className="container">
                <div className="text-center">
                  <h2 className="section-heading text-uppercase">{i18n.t('Experience')}</h2>
                  <h5 className="section-subheading text-muted">{i18n.t('ExperienceSubHead')}</h5> <br />
                </div>
                <OwlCarousel
                  options={options}
                >

                <div className="portfolio-item" >
                  <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1"><div className="portfolio-hover">

                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('CustomerService')}</h5></div></div>
                    </div>
                  <picture>
                    <source srcSet="clients/mcdonals.webp" type="image/webp" />
                    <source srcSet="clients/mcdonals.png" type="image/png" />
                    <img className="img-fluid" src="clients/mcdonals.png" style={{width: '100%',padding: '50px',height: '268px', objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                  </picture>
                    </a>
                  <div className="portfolio-caption">
                    <div className="portfolio-caption-heading">MC Donald’s </div>

                  </div>
                </div>
                <div className="portfolio-item">
                  <a className="portfolio-link" data-toggle="modal" href="#portfolioModal2"><div className="portfolio-hover">
                          <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%", paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('RoomManagement')}</h5></div></div>
                    </div>
                    <picture>
                      <source srcSet="clients/kempinski.webp" type="image/webp" />
                      <source srcSet="clients/kempinski.png" type="image/png" />
                      <img className="img-fluid" src="clients/kempinski.png" style={{width: '100%',padding: '50px',height: '268px', objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                    </picture>
                    </a>
                  <div className="portfolio-caption">
                    <div className="portfolio-caption-heading">Hotel Kempinski</div>
                  </div>
                </div>
          <div className="portfolio-item">
                  <a className="portfolio-link" data-toggle="modal" href="#portfolioModal3"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('PlateReco')}</h5></div></div>
                    </div>
                    <picture>
                          <source srcSet="clients/arena.webp" type="image/webp" />
                          <source srcSet="clients/arena.jpg" type="image/jpg" />
                          <img className="img-fluid" src="clients/arena.jpg" style={{width: '100%', height: '268px',padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                    </picture>
                        </a>
                  <div className="portfolio-caption">
                    <div className="portfolio-caption-heading">Arēna Rīga</div>
                  </div>
                </div>


          <div className="portfolio-item">
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal4"><div className="portfolio-hover">
                  <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('TimeAttendance')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/origo.webp" type="image/webp" />
                      <source srcSet="clients/origo.png" type="image/png" />
                      <img className="img-fluid" src="clients/origo.png" style={{width: '100%',height: '268px',padding: '50px', objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">ORIGO</div>

              </div>
            </div>
          <div className="portfolio-item">
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal5"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('TimeAttendance')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/lbp.webp" type="image/webp" />
                      <source srcSet="clients/lbp.jpg" type="image/jpg" />
                      <img className="img-fluid" src="clients/lbp.jpg" style={{width: '100%', height: '268px', padding: '50px', objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">LBP</div>
              </div>
            </div>
          <div className="portfolio-item">
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal6"><div className="portfolio-hover">
                  <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('TimeAttendance')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/upb.webp" type="image/webp" />
                      <source srcSet="clients/upb.jpg" type="image/jpg" />
                      <img className="img-fluid" src="clients/upb.jpg" style={{width: '100%', height: '268px',padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">UPB</div>
              </div>

            </div>

          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal7"><div className="portfolio-hover">
                  <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('ConstrNetworks')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/lukoil.webp" type="image/webp" />
                      <source srcSet="clients/lukoil.png" type="image/png" />
                      <img className="img-fluid" src="clients/lukoil.png" style={{width: '100%', height: '268px', padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Lukoil</div>
              </div>
            </div>
          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal8"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('CCTV')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/madara89.webp" type="image/webp" />
                      <source srcSet="clients/madara89.png" type="image/png" />
                      <img className="img-fluid" src="clients/madara89.png" style={{width: '100%', height: '268px',padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Madara 89</div>
              </div>
            </div>
          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal9"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('Logistic')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/havi.webp" type="image/webp" />
                      <source srcSet="clients/havi.jpg" type="image/jpg" />
                      <img className="img-fluid" src="clients/havi.jpg" style={{width: '100%', height: '268px',padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Havi Logistics</div>

            </div>
            </div>

          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal10"><div className="portfolio-hover">
                  <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('TimeAttendance')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/reaton.webp" type="image/webp" />
                      <source srcSet="clients/reaton.png" type="image/png" />
                      <img className="img-fluid" src="clients/reaton.png" style={{width: '100%', height: '268px', padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">REATON</div>
              </div>
            </div>
          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal11"><div className="portfolio-hover">
                  <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('CCTV')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/abparks.webp" type="image/webp" />
                      <source srcSet="clients/abparks.jpg" type="image/jpg" />
                      <img className="img-fluid" src="clients/abparks.jpg" style={{width: '100%', height: '268px', padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">AB Parks</div>
              </div>
            </div>
          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal12"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('TimeAttendance')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/sky.webp" type="image/webp" />
                      <source srcSet="clients/sky.jpg" type="image/jpg" />
                      <img className="img-fluid" src="clients/sky.jpg" style={{width: '100%', height: '268px', padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">SKY</div>
              </div>
            </div>

          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal13"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('CCTV')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/latraps.webp" type="image/webp" />
                      <source srcSet="clients/latraps.jpg" type="image/jpg" />
                      <img className="img-fluid" src="clients/latraps.jpg" style={{width: '100%', height: '268px', padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">LATRAPS</div>
              </div>
            </div>
          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal14"><div className="portfolio-hover">
                  <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('CCTV')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/tallink.webp" type="image/webp" />
                      <source srcSet="clients/tallink.png" type="image/png" />
                      <img className="img-fluid" src="clients/tallink.png" style={{width: '100%', height: '268px',padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">TALLINK</div>
              </div>
            </div>
          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal15"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('CCTV')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/aptieka.webp" type="image/webp" />
                      <source srcSet="clients/aptieka.png" type="image/png" />
                      <img className="img-fluid" src="clients/aptieka.png" style={{width: '100%', height: '268px', padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Mēness Aptieka</div>
              </div>
            </div>

          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal16"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('TimeAttendance')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/forta.webp" type="image/webp" />
                      <source srcSet="clients/forta.jpg" type="image/jpg" />
                      <img className="img-fluid" src="clients/forta.jpg" style={{width: '100%', height: '268px', padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Forta Pro</div>
              </div>
            </div>
          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal17"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('TimeAttendance')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/bite.webp" type="image/webp" />
                      <source srcSet="clients/bite.jpg" type="image/jpg" />
                      <img className="img-fluid" src="clients/bite.jpg" style={{width: '100%', height: '268px',padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">BITE LATVIJA</div>
              </div>
            </div>
          <div className="portfolio-item" style={{backgroundColor: 'white'}}>
              <a className="portfolio-link" data-toggle="modal" href="#portfolioModal18"><div className="portfolio-hover">
                      <div className="portfolio-hover-content"> <div  className="col-md-12" style={{width:"100%" , paddingHorizontal: '50px'}}><h5 style={{width:"100%", fontSize: '1.4rem'}}>{i18n.t('PeopleFlow')}</h5></div></div>
                </div>
                <picture>
                      <source srcSet="clients/spice.png" type="image/png" />
                      <source srcSet="clients/spice.png" type="image/png" />
                      <img className="img-fluid" src="clients/spice.png" style={{width: '100%', height: '268px', padding: '50px',objectFit: 'contain', backgroundColor: 'white'}} alt="links" />
                </picture>
                </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">SPICE</div>

              </div>
            </div>
                </OwlCarousel>
              </div>
            </section>
        )
    }
}

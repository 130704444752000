import React from 'react';
import '../../App.css';
import i18n from '../../i18n';

import { Link } from 'react-router-dom'
import Team from '../../components/team.js';
import Nav from '../../components/nav.js';

import Footer from '../../components/footer.js'
import CookieConsent from "react-cookie-consent";
import { withNamespaces } from 'react-i18next';
const posts = [
  {
    title: `Projekta "Platforma Covid-19 drošai darba videi" (identifikācijas nr 1.1.1.1/21/A/011) trešā ceturkšņa pārksats.`,
    content: 'Projekta ietvaros, trešā ceturkšņa izskaņā apkopoto darbu sarakstā jau ir manāma prototipa izstrāde. Tika finalizēta programmatūras struktūra un projektēšana, kā arī nonākts galā ar pētījumu par nepieciešamo iekārtu iegādi datu ievākšanai. Pirmās izstrādes fāzes ietvaros tika izveidota struktūra jau padziļinātā programmatūras izveides līmenī, kas sevī ietver datu relāciju definēšanu, datu apstrādes algoritmu izveidi un kā arī kopējās lietotāja vietnes izskata dizainu.',
    date: '01.10.2022',
    // label: 'WH Time & Attendance',
    url: '/blog/eraf/thirdQuarter',
    img: 'assets/blog/blog-2.png',
  }

]
class SinglePost extends React.Component {
  constructor(props) {
      super(props);

      this.state = {

      };
  }

  render(){
      const {  dati, loading, items } = this.state;
        return (
          <>
            <div className="blog container-fluid col-md-12" id="blog" style={{ height: '100%'}}>
            <Nav />
                <div style={{overflow: 'hidden', justifyContent: 'center'}} className="row col-md-12">
                <div to="/blog/dlus-cena" className="blog-single col-md-12" style={{ borderRadius: 15, backgroundColor: 'rgb(36, 40, 48) !important'}}>

                <div className="img col-md-12" style={{ width: '35%' }}>
                   <img src={posts[0].img} alt="Portfolio Item" />
                </div>
                <div className="col-md-12 mt-3">
                  <h3> {posts[0].title} </h3>
                </div>
                <div className="col-md-8 mt-2">

                <p> {posts[0].content} </p>
                </div>
                  </div>
                  </div>
                  <Team />
            </div>
            <Footer color="white" bgColor="#242830" />
            </>
        );
      }
  }



export default withNamespaces()(SinglePost);

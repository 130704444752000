import React from 'react';
import i18n from '../i18n';
export default class Services extends React.Component {
    simulateClick(e) {
        console.log(e)
  }
  componentDidMount() {
      window.jQuery("#filters #first").trigger('click');
  }
    render(){
        return(
            <section className="page-section" id="services" style={{overflow:'hidden', minHeight: '90vh'}}>
              <div className="container" style={{minHeight: '400px'}}>
                <div className="text-center">
                  <h2 className="section-heading text-uppercase">{i18n.t('Services')}</h2>
                  <div className="row justify-content-center mb-5" data-aos="fade-up">
                    <div id="filters" className="filters text-center button-group col-md-12">
                      <button   className="btn btn-primary active" style={{width: '250px', height: '20vh', fontWeight: 'bold', marginBottom: 10,marginLeft: 10}} data-filter=".web">{i18n.t('ServicesTab1Header')}</button>
                      <button className="btn btn-primary" style={{width: '250px', height: '20vh', fontWeight: 'bold',  marginBottom: 10, marginLeft: 10}} data-filter=".security">{i18n.t('ServicesTab2Header')}</button>
                      <button  id="first" className="btn btn-primary" style={{width: '250px',  height: '20vh', fontWeight: 'bold', marginBottom: 10, marginLeft: 10}} data-filter=".telec">{i18n.t('ServicesTab3Header')}</button>
                      <button  className="btn btn-primary" style={{width: '250px' ,  height: '20vh', fontWeight: 'bold', marginBottom: 10,marginLeft: 10}} data-filter=".wires">{i18n.t('ServicesTab4Header')}</button>
                    </div>
                  </div>
                </div>
                {/* Privātpersonas */}
                <div id="posts"  className="row no-gutter " style={{ marginTop: '10px'}}>
                  <div className=" item web col-lg-3 col-sm-6 mb-3 col-xs-4"  style={{textAlign: 'center'}}>
                    <span className="fa-stack fa-4x" style={{textAlign: 'center'}}><i style={{ color: 'rgba(252,76,61,1)'}}className="fas fa-file-code fa-stack-1x fa-inverse" /></span>
                    <h4 className="my-3">{i18n.t('ServicesTab1-1')}</h4>
                  </div>
                  <div className="  item web col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                    <span className="fa-stack fa-4x" style={{textAlign: 'center'}}><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-tasks fa-stack-1x fa-inverse" /></span>
                    <h4 className="my-3">{i18n.t('ServicesTab1-2')}</h4>
                  </div>
                  <div className=" item web col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                    <span className="fa-stack fa-4x" style={{textAlign: 'center'}}><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-analytics fa-stack-1x fa-inverse" /></span>
                    <h4 className="my-3">{i18n.t('ServicesTab1-3')}</h4>
                  </div>
                  <div className=" item web col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                    <span className="fa-stack fa-4x" style={{textAlign: 'center'}}><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-id-card-alt fa-stack-1x fa-inverse" /></span>
                    <h4 className="my-3">{i18n.t('ServicesTab1-4')}</h4>
                  </div>



                  {/* INOVĀCIJAS */}
                  <div className=" item security col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                    <span className="fa-stack fa-4x" style={{textAlign:'center'}}><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-cctv fa-stack-1x fa-inverse" /></span>
                    <h4 className="my-3">{i18n.t('ServicesTab2-1')}</h4>
                  </div>
                  <div className=" item security col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                    <span className="fa-stack fa-4x"><i  style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-car-bus  fa-stack-1x fa-inverse" /></span>
                    <h4 className="my-3">{i18n.t('ServicesTab2-2')}</h4>
                  </div>
                  <div className=" item security col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                    <span className="fa-stack fa-4x"><i  style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-id-card-alt  fa-stack-1x fa-inverse" /></span>
                    <h4 className="my-3">{i18n.t('ServicesTab2-3')}</h4>
                  </div>
                  <div className=" item security col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                    <span className="fa-stack fa-4x"><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-sensor-on  fa-stack-1x fa-inverse" /></span>
                    <h4 className="my-3">{i18n.t('ServicesTab2-4')}</h4>

                </div>
                <div className=" item telec col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                  <span className="fa-stack fa-4x"><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-router fa-stack-1x fa-inverse" /></span>
                  <h4 className="my-3">{i18n.t('ServicesTab3-1')}</h4>
                </div>
                <div className=" item telec col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                  <span className="fa-stack fa-4x"><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-tty fa-stack-1x fa-inverse" /></span>
                  <h4 className="my-3">{i18n.t('ServicesTab3-2')}</h4>
                </div>
                <div className=" item telec col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                  <span className="fa-stack fa-4x"><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-at fa-stack-1x fa-inverse" /></span>
                  <h4 className="my-3">{i18n.t('ServicesTab3-3')}</h4>
                </div>
                <div className=" item telec col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                  <span className="fa-stack fa-4x"><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-server fa-stack-1x fa-inverse" /></span>
                  <h4 className="my-3">{i18n.t('ServicesTab3-4')}</h4>
                </div>

                <div className=" item wires col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                  <span className="fa-stack fa-4x"><i  style={{ color: 'rgba(252,76,61,1)'}}className="fas fa-ethernet fa-stack-1x fa-inverse" /></span>
                  <h4 className="my-3">{i18n.t('ServicesTab4-1')}</h4>
                </div>
                <div className=" item wires col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                  <span className="fa-stack fa-4x"><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-podcast fa-stack-1x fa-inverse" /></span>
                  <h4 className="my-3">{i18n.t('ServicesTab4-2')}</h4>
                </div>
                <div className=" item wires col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                  <span className="fa-stack fa-4x"><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-wifi fa-stack-1x fa-inverse" /></span>
                  <h4 className="my-3">{i18n.t('ServicesTab4-3')}</h4>
                </div>
                <div className=" item wires col-lg-3 col-sm-6 mb-3 col-xs-4" style={{textAlign: 'center'}}>
                  <span className="fa-stack fa-4x"><i style={{ color: 'rgba(252,76,61,1)'}} className="fas fa-network-wired fa-stack-1x fa-inverse" /></span>
                  <h4 className="my-3">{i18n.t('ServicesTab4-4')}</h4>

              </div>
              </div>
              </div>
            </section>
        )
    }
}

import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import App from '../App.js'
import Blog from '../views/blog/blog'
import SecondPost from '../views/blog/secondPost'
import ThirdPost from '../views/blog/thirdPost'
import FourthPost from '../views/blog/fourthPost'
import FifthPost from '../views/blog/fifthPost'
import SixthPost from '../views/blog/sixthPost'
export default function Base() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/blog/eraf" exact component={SecondPost} />
        <Route exact path="/blog/eraf/firstQuarter" exact component={ThirdPost} />
          <Route exact path="/blog/eraf/secondQuarter" exact component={FourthPost} />
        <Route exact path="/blog/eraf/thirdQuarter" exact component={FifthPost} />
        <Route exact path="/blog/eraf/fourthQuarter" exact component={SixthPost} />


      </Switch>
    </Router>
  );
}
